import get from '@invitato/helpers/dist/getLocale';

export default {
  to: get('Nama', 'Name'),
  desc: get('Keterangan', 'Description'),
  important: get('Penting', 'Important'),
  importantDesc: get(
    'Silakan tunjukkan QRCode disamping kepada usher yang bertugas di lokasi acara.',
    'Please show the QR code to enter the event venue.',
  ),
};
