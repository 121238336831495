import { useLocation } from '@reach/router';
import getQueryValue from '@invitato/helpers/dist/getQueryStringValue';

import guestList from '@/api/guest-data.json';
import { ENABLE_QR_INVITATION, ENABLE_QR_NAME_VALIDATION } from '@/constants/feature-flags';
/**
 * Custom hook to return guest info data from Query string data
 * @return {object}
 */
function useGuestInfo() {
  const location = useLocation();
  const code = getQueryValue(location, 'code');
  const name = getQueryValue(location, 'to');
  const finalCode = decodeURIComponent(code);

  if (!ENABLE_QR_INVITATION) {
    return { name };
  }

  // validate Guest Info data by feature flag
  const validateGuestInfo = (guest) => {
    const isValidCode = (guest?.code || '').toLowerCase() === finalCode.toLowerCase();

    if (!ENABLE_QR_NAME_VALIDATION) {
      return isValidCode;
    }

    return isValidCode && (guest?.name || '').toLowerCase() === name.toLowerCase();
  }

  const guestInfo = guestList.find(validateGuestInfo) || {};
  return guestInfo;
}

export default useGuestInfo;
